import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { projectExportExcelAction } from '@/redux/actions/project-export';
import { projectExportExcel, TProjectExportExcelResponse } from '@/services/api/project-export';

// FUNCTION
export function* projectExportExcelSaga(action: ActionType<typeof projectExportExcelAction.request>): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(projectExportExcel, companyId, projectId);
    const projectExportExcelResponse: TProjectExportExcelResponse = response as TProjectExportExcelResponse;
    yield put(projectExportExcelAction.success(projectExportExcelResponse));
    successCallback?.(projectExportExcelResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getProjectExcelFileError');
    yield put(projectExportExcelAction.failure(err));
    failedCallback?.(err);
  }
}
