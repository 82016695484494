import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkAccessToken } from '@/utils/handle-authentication';
import { changePasswordAction } from '@/redux/actions/auth';
import { changePassword, TChangePasswordResponse } from '@/services/api/auth';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* changePasswordSaga(action: ActionType<typeof changePasswordAction.request>): Generator {
  const { materials, userId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(changePassword, materials, userId);
    const changePasswordResponse: TChangePasswordResponse = response as TChangePasswordResponse;
    yield put(changePasswordAction.success(changePasswordResponse));
    successCallback?.(changePasswordResponse);
  } catch (err) {
    yield put(changePasswordAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.changePasswordError');
  }
}
