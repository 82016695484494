import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { handleAPIError } from '@/utils/handle-api-error';
import { resendOtpAction } from '@/redux/actions/auth';
import { resendOtp, TResendOtpResponse } from '@/services/api/auth';

// FUNCTION
export function* resendOtpSaga(action: ActionType<typeof resendOtpAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(resendOtp, materials);
    const resendOtpResponse: TResendOtpResponse = response as TResendOtpResponse;
    yield put(resendOtpAction.success(resendOtpResponse));
    successCallback?.(resendOtpResponse);
  } catch (err) {
    handleAPIError(err, 'Error.resendOTPError');
    yield put(resendOtpAction.failure(err));
    failedCallback?.(err);
  }
}
