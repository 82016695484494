import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkAccessToken } from '@/utils/handle-authentication';
import { getProjectDetailAction } from '@/redux/actions/project-detail';
import { getProjectDetail, TGetProjectDetailResponse } from '@/services/api/project-detail';

// FUNCTION
export function* getProjectDetailSaga(action: ActionType<typeof getProjectDetailAction.request>): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(getProjectDetail, companyId, projectId);
    const getProjectDetailResponse: TGetProjectDetailResponse = response as TGetProjectDetailResponse;
    yield put(getProjectDetailAction.success(getProjectDetailResponse));
    successCallback?.(getProjectDetailResponse);
  } catch (err) {
    yield put(getProjectDetailAction.failure(err));
    failedCallback?.(err);
  }
}
