import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { handleAPIError } from '@/utils/handle-api-error';
import { verifyOtpAction } from '@/redux/actions';
import { verifyOtp, TVerifyOtpResponse } from '@/services/api';

// FUNCTION
export function* verifyOtpSaga(action: ActionType<typeof verifyOtpAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(verifyOtp, materials);
    const verifyOtpResponse: TVerifyOtpResponse = response as TVerifyOtpResponse;
    yield put(verifyOtpAction.success(verifyOtpResponse));
    successCallback?.(verifyOtpResponse);
  } catch (err) {
    handleAPIError(err, 'Error.verifyRegisterEmailError');
    yield put(verifyOtpAction.success(err));
    failedCallback?.(err);
  }
}
