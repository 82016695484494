import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { getListStateAction } from '@/redux/actions/location';
import { getListState, TGetListStateResponse } from '@/services/api/location';
import message from '@/utils/message';

// FUNCTION - ENDPOINT IS NOT BEING USED
export function* getListStateSaga(action: ActionType<typeof getListStateAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getListState, materials);
    const getListStateResponse: TGetListStateResponse = response as TGetListStateResponse;
    yield put(getListStateAction.success(getListStateResponse));
    successCallback?.(getListStateResponse);
  } catch (err) {
    message.error('getListStateSaga error:', err.data.status_message);
    yield put(getListStateAction.failure(err));
    failedCallback?.(err);
  }
}
