import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { postProjectSettingAction } from '@/redux/actions/project-setting';
import { postProjectSetting, TPostProjectSettingResponse } from '@/services/api/project-setting';

// FUNCTION
export function* postProjectSettingSaga(action: ActionType<typeof postProjectSettingAction.request>): Generator {
  const { materials, companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(postProjectSetting, materials, companyId, projectId);
    const postProjectSettingResponse: TPostProjectSettingResponse = response as TPostProjectSettingResponse;
    yield put(postProjectSettingAction.success(postProjectSettingResponse));
    successCallback?.(postProjectSettingResponse);
  } catch (err) {
    yield put(postProjectSettingAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateProjectSettingError');
  }
}
