import React, { lazy, Suspense, useEffect, useState, FC } from 'react';

import { useDispatch } from 'react-redux';
import { Redirect, RouteComponentProps, useLocation } from '@reach/router';

import { getUserInfoAction } from '@/redux/actions';
import { handleAPIError } from '@/utils/handle-api-error';
import Helpers from '@/services/helpers';
import Loading from '@/components/Loading';
import AuthHelpers from '@/services/helpers';
import { TUserInfo } from '@/services/api';

const retryLoadComponent = (fn: () => Promise<unknown>, retriesLeft = 5, interval = 1000): any =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retryLoadComponent(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const Home = lazy(() => retryLoadComponent(() => import('@/pages/Home')));
const Login = lazy(() => retryLoadComponent(() => import('@/pages/Login')));
const Project = lazy(() => retryLoadComponent(() => import('@/pages/Project')));
const Account = lazy(() => retryLoadComponent(() => import('@/pages/Account')));

export const LayoutPaths = {
  Guest: '/',
  Auth: '/',
  Project: '/projects',
  Admin: '/admin',
};

export const ModulePaths = {};

export const Paths = {
  Home: '/',
  Login: '/login',
  Project: '/projects',
  Account: '/account',
  Rest: '*',
};

export const Pages = {
  Home,
  Login,
  Project,
  Account,
};

interface IRouteProps extends RouteComponentProps {
  component: any;
}

export const AuthRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const loggedIn: string | any = Helpers.getAccessToken();
  const TypeScriptRedirect = Redirect as unknown as FC<any>;

  return loggedIn ? (
    <TypeScriptRedirect noThrow from={Paths.Rest} to={LayoutPaths.Admin} />
  ) : (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};

const RouteWrapper = ({ component: Component, ...rest }): any => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [userUpdateState, setUserUpdateState] = useState(false);

  useEffect(() => {
    dispatch(
      getUserInfoAction.request((response: any): any => {
        if (response.status === 200) {
          AuthHelpers.storeUserInfo(response.data as TUserInfo);
          setUserUpdateState((prevState) => !prevState);
        } else handleAPIError(response, 'Error.getUserInfoError');
      }),
    );
  }, [dispatch, location]);

  return <Component location={location} userUpdateState={userUpdateState} {...rest} />;
};

export const ProtectedRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const refreshToken = Helpers.getRefreshToken();
  const TypeScriptRedirect = Redirect as unknown as FC<any>;
  const dispatch = useDispatch();
  if (!refreshToken) return <TypeScriptRedirect noThrow from={Paths.Rest} to={Paths.Login} />;
  else {
    const accessToken = Helpers.getAccessToken();
    if (!accessToken) {
      dispatch(
        getUserInfoAction.request((response: any): any => {
          if (response.status === 200) AuthHelpers.storeUserInfo(response.data as TUserInfo);
        }),
      );
    }
    return <RouteWrapper {...rest} component={Component} />;
  }
};

export const PublicRoute: React.FC<IRouteProps> = ({ component: Component, ...rest }) => {
  const refreshToken = Helpers.getRefreshToken();
  const TypeScriptRedirect = Redirect as unknown as FC<any>;
  const dispatch = useDispatch();
  if (refreshToken) {
    dispatch(
      getUserInfoAction.request((response: any): any => {
        if (response.status !== 200) handleAPIError(response, 'Error.getUserInfoError');
      }),
    );
    return <TypeScriptRedirect noThrow from={Paths.Rest} to={LayoutPaths.Project} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};
