import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { getListEmployeeOutsideAction } from '@/redux/actions/employee';
import { getListEmployeeOutside, TGetListEmployeeOutsideResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* getListEmployeeOutsideSaga(
  action: ActionType<typeof getListEmployeeOutsideAction.request>,
): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(getListEmployeeOutside, companyId, projectId);
    const getListEmployeeInsideResponse: TGetListEmployeeOutsideResponse = response as TGetListEmployeeOutsideResponse;
    yield put(getListEmployeeOutsideAction.success(getListEmployeeInsideResponse));
    successCallback?.(getListEmployeeInsideResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getListEmployeeOutsideProjectError');
    yield put(getListEmployeeOutsideAction.failure(err));
    failedCallback?.(err);
  }
}
