import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { updateProfileEmployeeAction } from '@/redux/actions/employee';
import { updateProfileEmployee, TUpdateProfileEmployeeResponse } from '@/services/api/employee';

// FUNCTION
export function* updateProfileEmployeeSaga(action: ActionType<typeof updateProfileEmployeeAction.request>): Generator {
  const { materials, companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(updateProfileEmployee, materials, companyId, employeeId);
    const updateProfileEmployeeResponse: TUpdateProfileEmployeeResponse = response as TUpdateProfileEmployeeResponse;
    yield put(updateProfileEmployeeAction.success(updateProfileEmployeeResponse));
    successCallback?.(updateProfileEmployeeResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(updateProfileEmployeeAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.editEmployeeInfoError');
  }
}
