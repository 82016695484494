import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { saveProjectDetailAction } from '@/redux/actions/project-detail';
import { saveProjectDetail, TSaveProjectDetailResponse } from '@/services/api/project-detail';

// FUNCTION
export function* saveProjectDetailSaga(action: ActionType<typeof saveProjectDetailAction.request>): Generator {
  const { materials, companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(saveProjectDetail, materials, companyId, projectId);
    const saveProjectDetailResponse: TSaveProjectDetailResponse = response as TSaveProjectDetailResponse;
    yield put(saveProjectDetailAction.success(saveProjectDetailResponse));
    successCallback?.(saveProjectDetailResponse);
  } catch (err) {
    yield put(saveProjectDetailAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateProjectWorkbookError');
  }
}
