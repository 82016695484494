import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { copyProjectAction } from '@/redux/actions/project';
import { copyProject, TCopyProjectResponse } from '@/services/api/project';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* copyProjectSaga(action: ActionType<typeof copyProjectAction.request>): Generator {
  const { materials, companyId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(copyProject, materials, companyId);
    const copyProjectResponse: TCopyProjectResponse = response as TCopyProjectResponse;
    yield put(copyProjectAction.success(copyProjectResponse));
    successCallback?.(copyProjectResponse);
  } catch (err) {
    yield put(copyProjectAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.copyProjectError');
  }
}
