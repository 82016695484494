import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { getUserInfoAction } from '@/redux/actions/auth';
import { getUserInfo, TGetUserInfoResponse, TUserInfo } from '@/services/api/auth';
import { handleAPIError } from '@/utils/handle-api-error';
import AuthHelpers from '@/services/helpers';
import Helpers from '@/services/helpers';

// FUNCTION
export function* getUserInfoSaga(action: ActionType<typeof getUserInfoAction.request>): Generator {
  const { successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const getUserResponse = (yield call(getUserInfo)) as unknown as TGetUserInfoResponse;
    if (getUserResponse.status === 200) {
      Helpers.storeUserInfo(getUserResponse.data);
      AuthHelpers.storeUserInfo(getUserResponse.data as TUserInfo);
    }
    yield put(getUserInfoAction.success(getUserResponse));
    successCallback?.(getUserResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getUserInfoError');
    yield put(getUserInfoAction.failure(err));
    failedCallback?.(err);
  }
}
