import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { projectExportPdf, TProjectExportPdfResponse } from '@/services/api/project-export';
import { projectExportPdfAction } from '@/redux/actions/project-export';

// FUNCTION
export function* projectExportPdfSaga(action: ActionType<typeof projectExportPdfAction.request>): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(projectExportPdf, companyId, projectId);
    const projectExportPdfResponse: TProjectExportPdfResponse = response as TProjectExportPdfResponse;
    yield put(projectExportPdfAction.success(projectExportPdfResponse));
    successCallback?.(projectExportPdfResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getProjectPDFFileError');
    yield put(projectExportPdfAction.failure(err));
    failedCallback?.(err);
  }
}
