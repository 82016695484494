import { createActionCreator } from 'deox';

import { TPostCompanyImageUserBody, TPostCompanyImageUserResponse } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EPostCompanyImageUserAction {
  POST_COMPANYIMAGEUSER = 'POST_COMPANYIMAGEUSER',
  POST_COMPANYIMAGEUSER_REQUEST = 'POST_COMPANYIMAGE_REQUESTUSER',
  POST_COMPANYIMAGEUSER_SUCCESS = 'POST_COMPANYIMAGE_SUCCESSUSER',
  POST_COMPANYIMAGEUSER_FAILED = 'POST_COMPANYIMAGE_FAILEDUSER',
}

// TYPES

export type TPostCompanyImageUserRequest = {
  type: EPostCompanyImageUserAction.POST_COMPANYIMAGEUSER_REQUEST;
  payload: {
    materials: TPostCompanyImageUserBody;
    companyId: number;
    successCallback?: (response: TPostCompanyImageUserResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostCompanyImageUserSuccess = {
  type: EPostCompanyImageUserAction.POST_COMPANYIMAGEUSER_SUCCESS;
  payload: { response: TPostCompanyImageUserResponse };
};

export type TPostCompanyImageUserFailed = { type: EPostCompanyImageUserAction.POST_COMPANYIMAGEUSER_FAILED };

// FUNCTION

export const postCompanyImageUserAction = {
  request: createActionCreator(
    EPostCompanyImageUserAction.POST_COMPANYIMAGEUSER_REQUEST,
    (resolve) =>
      (
        materials: TPostCompanyImageUserBody,
        companyId: number,
        successCallback?: (response: TPostCompanyImageUserResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostCompanyImageUserRequest => {
        return resolve({ materials, companyId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EPostCompanyImageUserAction.POST_COMPANYIMAGEUSER_SUCCESS,
    (resolve) =>
      (response: TPostCompanyImageUserResponse): TPostCompanyImageUserSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EPostCompanyImageUserAction.POST_COMPANYIMAGEUSER_FAILED,
    (resolve) =>
      (error: any): TPostCompanyImageUserFailed => {
        message.error('SAVE_COMPANYIMAGEUSER_FAILED Action:', error.data.status_message);
        return resolve({ error });
      },
  ),
};
