import ApiService from '@/services/api';

// TYPES
export type TPostCompanyImageBody = {
  file: string;
};
export type TPostCompanyImageResponse = unknown;

// FUNCTION
export const postCompanyImage = async (
  requestBody: TPostCompanyImageBody,
  companyId: number,
  projectId: number,
): Promise<TPostCompanyImageResponse> => {
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/upload-company-logo/`;
  const response = await ApiService.post(endpoint, requestBody, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response;
};
