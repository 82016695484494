import ApiService from '@/services/api';

// TYPES
export type TPostCompanyImageUserBody = {
  file: string;
};
export type TPostCompanyImageUserResponse = unknown;

// FUNCTION
export const postCompanyImageUser = async (
  requestBody: TPostCompanyImageUserBody,
  companyId: number,
): Promise<TPostCompanyImageUserResponse> => {
  const endpoint = `/v1/companies/${companyId}/upload-company-logo/`;
  const response = await ApiService.post(endpoint, requestBody, { headers: { 'Content-Type': 'multipart/form-data' } });
  return response;
};
