import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { getUnassignProjectAction } from '@/redux/actions';
import { getUnassignProject, TGetUnassignProjectResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* getUnassignProjectSaga(action: ActionType<typeof getUnassignProjectAction.request>): Generator {
  const { companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(getUnassignProject, companyId, employeeId);
    const getUnassignProjectResponse: TGetUnassignProjectResponse = response as TGetUnassignProjectResponse;
    yield put(getUnassignProjectAction.success(getUnassignProjectResponse));
    successCallback?.(getUnassignProjectResponse);
  } catch (err) {
    handleAPIError(err, 'Error.listProjectByEmployeeError');
    yield put(getUnassignProjectAction.failure(err));
    failedCallback?.(err);
  }
}
