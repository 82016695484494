import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkAccessToken } from '@/utils/handle-authentication';
import { postProjectDetailAction } from '@/redux/actions/project-detail';
import { postProjectDetail, TPostProjectDetailResponse } from '@/services/api/project-detail';

// FUNCTION
export function* postProjectDetailSaga(action: ActionType<typeof postProjectDetailAction.request>): Generator {
  const { companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(postProjectDetail, companyId, projectId);
    const postProjectDetailResponse: TPostProjectDetailResponse = response as TPostProjectDetailResponse;
    yield put(postProjectDetailAction.success(postProjectDetailResponse));
    successCallback?.(postProjectDetailResponse);
  } catch (err) {
    yield put(postProjectDetailAction.failure(err));
    failedCallback?.(err);
  }
}
