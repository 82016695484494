import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { postCompanyImageUserAction } from '@/redux/actions/employee';
import { postCompanyImageUser, TPostCompanyImageUserResponse } from '@/services/api/employee';

// FUNCTION
export function* postCompanyImageUserSaga(action: ActionType<typeof postCompanyImageUserAction.request>): Generator {
  const { materials, companyId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(postCompanyImageUser, materials, companyId);
    const postCompanySettingResponse: TPostCompanyImageUserResponse = response as TPostCompanyImageUserResponse;
    yield put(postCompanyImageUserAction.success(postCompanySettingResponse));
    successCallback?.(postCompanySettingResponse);
  } catch (err) {
    yield put(postCompanyImageUserAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateCompanyInfoSettingError');
  }
}
