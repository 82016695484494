import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { postUnassignProject, TUnassignProjectResponse } from '@/services/api/employee';
import { unassignProjectAction } from '@/redux/actions';

// FUNCTION
export function* postUnassignProjectSaga(action: ActionType<typeof unassignProjectAction.request>): Generator {
  const { materials, companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(postUnassignProject, materials, companyId, employeeId);
    const unassignProjectResponse: TUnassignProjectResponse = response as TUnassignProjectResponse;
    yield put(unassignProjectAction.success(unassignProjectResponse));
    successCallback?.(unassignProjectResponse);
  } catch (err) {
    handleAPIError(err, 'Error.listProjectByEmployeeError');
    yield put(unassignProjectAction.failure(err));
    failedCallback?.(err);
  }
}
