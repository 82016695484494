import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { getBackendVersionAction } from '@/redux/actions/general';
import { getBackendVersion, TGetBackendVersionResponse } from '@/services/api/general';
import message from '@/utils/message';

// FUNCTION - ENDPOINT IS NOT BEING USED

export function* getBackendVersionSaga(action: ActionType<typeof getBackendVersionAction.request>): Generator {
  const { successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getBackendVersion);
    const getBackendVersionResponse: TGetBackendVersionResponse = response as TGetBackendVersionResponse;
    yield put(getBackendVersionAction.success(getBackendVersionResponse));
    successCallback?.(getBackendVersionResponse);
  } catch (err) {
    message.error('getBackendVersionSaga error:', err.data.status_message);
    yield put(getBackendVersionAction.failure(err));
    failedCallback?.(err);
  }
}
