import { call } from 'redux-saga/effects';
import { refreshToken, TAuthRefreshTokenResponse } from '@/services/api/auth';
import Helpers from '@/services/helpers';

export const calculateRefreshTokenTime = (response: any): number => {
  const refreshTokenTimeMs = response.data.refresh_token_time * 24 * 60 * 60;
  return refreshTokenTimeMs;
};

export const calculateAccessTokenTime = (response: any): number => {
  const accessTokenExpiredTime = response.data.access_token_time * 60;
  return accessTokenExpiredTime;
};

export function* checkAccessToken(): Generator {
  const accessToken = Helpers.getAccessToken();
  if (!accessToken) {
    const refreshResponse = (yield call(refreshToken)) as unknown as TAuthRefreshTokenResponse;
    if (refreshResponse.status === 200) {
      refreshResponse.data = { ...refreshResponse.data, access_token_time: 15 };
      const accessTokenExpiredTime = calculateAccessTokenTime(refreshResponse);
      Helpers.storeAccessToken(refreshResponse.data.access_token, accessTokenExpiredTime);
    }
  }
}
