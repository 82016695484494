import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { getTermConditionAction } from '@/redux/actions/general';
import { getTermCondition, TGetTermConditionResponse } from '@/services/api/general';
import message from '@/utils/message';

// FUNCTION - ENDPOINT IS NOT BEING USED
export function* getTermConditionSaga(action: ActionType<typeof getTermConditionAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getTermCondition, materials);
    const getTermConditionResponse: TGetTermConditionResponse = response as TGetTermConditionResponse;
    yield put(getTermConditionAction.success(getTermConditionResponse));
    successCallback?.(getTermConditionResponse);
  } catch (err) {
    message.error('getTermConditionSaga error:', err.data.status_message);
    yield put(getTermConditionAction.failure(err));
    failedCallback?.(err);
  }
}
