import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { handleAPIError } from '@/utils/handle-api-error';
import { upgradeLicenseAction } from '@/redux/actions/company';
import { upgradeLicense, TUpgradeLicenseResponse } from '@/services/api/company';

// FUNCTION - ENDPOINT IS NOT BEING USED
export function* upgradeLicenseSaga(action: ActionType<typeof upgradeLicenseAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(upgradeLicense, materials);
    const upgradeLicenseResponse: TUpgradeLicenseResponse = response as TUpgradeLicenseResponse;
    yield put(upgradeLicenseAction.success(upgradeLicenseResponse));
    successCallback?.(upgradeLicenseResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(upgradeLicenseAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.freeUserUpgradeCreateCompanyError');
  }
}
