import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { unAssignUsersListAction } from '@/redux/actions/employee';
import { unAssignUsersList, TUnAssignUsersListResponse } from '@/services/api/employee';

// FUNCTION
export function* unAssignUsersListSaga(action: ActionType<typeof unAssignUsersListAction.request>): Generator {
  const { materials, companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(unAssignUsersList, materials, companyId, projectId);
    const unAssignUsersListResponse: TUnAssignUsersListResponse = response as TUnAssignUsersListResponse;
    yield put(unAssignUsersListAction.success(unAssignUsersListResponse));
    successCallback?.(unAssignUsersListResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(unAssignUsersListAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.unassignEmployeesListError');
  }
}
