import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { TourProvider } from '@reactour/tour';
import configureStore from '@/redux/configureStore';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';

import { cellActivateAction } from './redux/actions/interaction';
import { stepsMainDE, stepsMainEN } from './common/guidetour/stepsMain';
import App from './App';
import Button from './components/Button';
import FloatButton from './components/FloatButton';
import next from './assets/icons/next.svg';
import prev from './assets/icons/prev.svg';

import './assets/styles/_main.scss';

const store = configureStore();

const TourApp = (): any => {
  const [isTourReady, setTourReady] = useState(false);
  const { i18n } = useTranslation();
  const steps = i18n.language === 'de' ? stepsMainDE() : stepsMainEN();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setTourReady(true);
    }, 0);
    return () => {
      enableBodyScroll();
    };
  }, [steps]);

  const disableBodyScroll = (): any => {
    document.body.classList.add('no-scroll');
    const tabs = document.getElementsByClassName('ant-tabs-content-holder');
    const scrollers = document.getElementsByClassName('dvn-scroller');
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i]) {
        const tab = tabs[i] as HTMLElement;
        tab.style.overflowY = 'hidden';
      }
    }
    for (let i = 0; i < scrollers.length; i++) {
      if (scrollers[i]) {
        const scroller = scrollers[i] as HTMLElement;
        scroller.style.overflowX = 'hidden';
      }
    }
  };

  const enableBodyScroll = (): any => {
    document.body.classList.remove('no-scroll');
    const tabs = document.getElementsByClassName('ant-tabs-content-holder');
    const scrollers = document.getElementsByClassName('dvn-scroller');
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i]) {
        const tab = tabs[i] as HTMLElement;
        tab.style.overflowY = 'auto';
      }
    }
    for (let i = 0; i < scrollers.length; i++) {
      if (scrollers[i]) {
        const scroller = scrollers[i] as HTMLElement;
        scroller.style.overflowX = 'auto';
      }
    }
  };

  const NextButton = (e): any => {
    const onNextSteps = (): any => {
      e.setCurrentStep(e.currentStep + 1);
      const randomNumber = Math.floor(Math.random() * 1000) + 1;
      dispatch(cellActivateAction.request({ CellActivate: 'onNext-' + randomNumber }));
    };

    return (
      <Button
        className="buttonGuide"
        type="primary"
        iconName={<img src={next} />}
        disabled={e.currentStep == e.steps.length - 1}
        onClick={onNextSteps}
      />
    );
  };

  const PrevButton = (e): any => {
    const onPrevSteps = (): any => {
      e.setCurrentStep(e.currentStep - 1);
      const randomNumber = Math.floor(Math.random() * 1000) + 1;
      dispatch(cellActivateAction.request({ CellActivate: 'onPrev-' + randomNumber }));
    };
    return (
      <Button
        className="buttonGuide"
        type="primary"
        iconName={<img src={prev} />}
        disabled={e.currentStep == 0}
        onClick={onPrevSteps}
      />
    );
  };

  return (
    <>
      {isTourReady ? (
        <TourProvider
          steps={steps}
          nextButton={NextButton}
          prevButton={PrevButton}
          maskClassName="custom-overlay"
          showBadge={true}
          styles={{
            badge: (base) => ({
              ...base,
              backgroundColor: '#183762',
            }),
            dot: (base, { current }) => ({
              ...base,
              backgroundColor: current ? '#183762' : 'white',
            }),
          }}
        >
          <App />
          {/* TODO: This button uses for the feedback  */}
          {/* <FloatButton title="Feedback" link="" /> */}
        </TourProvider>
      ) : (
        <>
          <App />
          {/* TODO: This button uses for the feedback  */}
          {/* <FloatButton title="Feedback" link="" /> */}
        </>
      )}
    </>
  );
};

const MainApp = (): any => {
  return (
    <Provider store={store}>
      <TourApp />
    </Provider>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<MainApp />);
}

reportWebVitals();
