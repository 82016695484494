import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { deleteListEmployeeAction } from '@/redux/actions/employee';
import { deleteListEmployee, TDeleteListEmployeeResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* deleteListEmployeeSaga(action: ActionType<typeof deleteListEmployeeAction.request>): Generator {
  const { companyId, employeeId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(deleteListEmployee, companyId, employeeId);
    const deleteListEmployeeResponse: TDeleteListEmployeeResponse = response as TDeleteListEmployeeResponse;
    yield put(deleteListEmployeeAction.success(deleteListEmployeeResponse));
    successCallback?.(deleteListEmployeeResponse);
  } catch (err) {
    yield put(deleteListEmployeeAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.deleteListEmployeesError');
  }
}
