import ApiService from '@/services/api';

// TYPES
export type TPostProjectDetailResponse = unknown;

//  FUNCTION
export const postProjectDetail = async (companyId: number, projectId: number): Promise<TPostProjectDetailResponse> => {
  const DHPDServer = localStorage.getItem('dhpd_server');
  const endpoint = `/v1/companies/${companyId}/projects/${projectId}/calculate/?dhpd_server=${DHPDServer}`;
  const response = await ApiService.get(endpoint);
  return response;
};
