import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { deleteProjectAction } from '@/redux/actions/project';
import { deleteProject, TDeleteProjectResponse } from '@/services/api/project';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* deleteProjectSaga(action: ActionType<typeof deleteProjectAction.request>): Generator {
  const { materials, companyId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(deleteProject, materials, companyId);
    const deleteProjectResponse: TDeleteProjectResponse = response as TDeleteProjectResponse;
    yield put(deleteProjectAction.success(deleteProjectResponse));
    successCallback?.(deleteProjectResponse);
  } catch (err) {
    yield put(deleteProjectAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.deleteProjectListError');
  }
}
