import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { handleAPIError } from '@/utils/handle-api-error';
import { requestVerifyUserEmailAction } from '@/redux/actions/auth/request-verify-user-email';
import { requestVerifyUserEmail, TRequestVerifyUserEmailResponse } from '@/services/api';

// FUNCTION - ENDPOINT IS NOT BEING USED
export function* requestVerifyUserEmailSaga(
  action: ActionType<typeof requestVerifyUserEmailAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(requestVerifyUserEmail, materials);
    const requestVerifyUserEmailResponse: TRequestVerifyUserEmailResponse = response as TRequestVerifyUserEmailResponse;
    yield put(requestVerifyUserEmailAction.success(requestVerifyUserEmailResponse));
    successCallback?.(requestVerifyUserEmailResponse);
  } catch (err) {
    handleAPIError(err, 'Error.verifyRegisterEmailError');
    yield put(requestVerifyUserEmailAction.failure(err));
    failedCallback?.(err);
  }
}
