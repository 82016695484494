import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkAccessToken } from '@/utils/handle-authentication';
import { postCompanyImageAction } from '@/redux/actions/project-setting';
import { postCompanyImage, TPostCompanyImageResponse } from '@/services/api/project-setting';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION
export function* postCompanyImageSaga(action: ActionType<typeof postCompanyImageAction.request>): Generator {
  const { materials, companyId, projectId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(postCompanyImage, materials, companyId, projectId);
    const postCompanySettingResponse: TPostCompanyImageResponse = response as TPostCompanyImageResponse;
    yield put(postCompanyImageAction.success(postCompanySettingResponse));
    successCallback?.(postCompanySettingResponse);
  } catch (err) {
    yield put(postCompanyImageAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateCompanyInfoSettingError');
  }
}
