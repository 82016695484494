import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { updateProfile, TUpdateProfileResponse } from '@/services/api/auth';
import { updateProfileAction } from '@/redux/actions/auth';

// FUNCTION
export function* updateProfileSaga(action: ActionType<typeof updateProfileAction.request>): Generator {
  const { materials, companyId, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(updateProfile, materials, companyId);
    const updateProfileResponse: TUpdateProfileResponse = response as TUpdateProfileResponse;
    yield put(updateProfileAction.success(updateProfileResponse));
    successCallback?.(updateProfileResponse);
  } catch (err) {
    yield put(updateProfileAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateCompanyInfoError');
  }
}
