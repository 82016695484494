import { createActionCreator } from 'deox';

import { TPostCompanyImageBody, TPostCompanyImageResponse } from '@/services/api/project-setting';
import message from '@/utils/message';

// CONSTANTS

export enum EPostCompanyImageAction {
  POST_COMPANYIMAGE = 'POST_COMPANYIMAGE',
  POST_COMPANYIMAGE_REQUEST = 'POST_COMPANYIMAGE_REQUEST',
  POST_COMPANYIMAGE_SUCCESS = 'POST_COMPANYIMAGE_SUCCESS',
  POST_COMPANYIMAGE_FAILED = 'POST_COMPANYIMAGE_FAILED',
}

// TYPES

export type TPostCompanyImageRequest = {
  type: EPostCompanyImageAction.POST_COMPANYIMAGE_REQUEST;
  payload: {
    materials: TPostCompanyImageBody;
    companyId: number;
    projectId: number;
    successCallback?: (response: TPostCompanyImageResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostCompanyImageSuccess = {
  type: EPostCompanyImageAction.POST_COMPANYIMAGE_SUCCESS;
  payload: { response: TPostCompanyImageResponse };
};

export type TPostCompanyImageFailed = { type: EPostCompanyImageAction.POST_COMPANYIMAGE_FAILED };

// FUNCTION

export const postCompanyImageAction = {
  request: createActionCreator(
    EPostCompanyImageAction.POST_COMPANYIMAGE_REQUEST,
    (resolve) =>
      (
        materials: TPostCompanyImageBody,
        companyId: number,
        projectId: number,
        successCallback?: (response: TPostCompanyImageResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostCompanyImageRequest => {
        return resolve({ materials, companyId, projectId, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EPostCompanyImageAction.POST_COMPANYIMAGE_SUCCESS,
    (resolve) =>
      (response: TPostCompanyImageResponse): TPostCompanyImageSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EPostCompanyImageAction.POST_COMPANYIMAGE_FAILED,
    (resolve) =>
      (error: any): TPostCompanyImageFailed => {
        message.error('SAVE_COMPANYIMAGE_FAILED Action:', error.data.status_message);
        return resolve({ error });
      },
  ),
};
