import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { postCompanySettingAction } from '@/redux/actions/project-setting';
import { postCompanySetting, TPostCompanySettingResponse } from '@/services/api/project-setting';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION -ENDPOINT IS NOT USED
export function* postCompanySettingSaga(action: ActionType<typeof postCompanySettingAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(postCompanySetting, materials);
    const postCompanySettingResponse: TPostCompanySettingResponse = response as TPostCompanySettingResponse;
    yield put(postCompanySettingAction.success(postCompanySettingResponse));
    successCallback?.(postCompanySettingResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(postCompanySettingAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateCompanyInfoSettingError');
  }
}
