import { call, put } from 'redux-saga/effects';
import { ActionType } from 'deox';

import { handleAPIError } from '@/utils/handle-api-error';
import { sendEmailAction } from '@/redux/actions/company';
import { sendEmail, TSendEmailResponse } from '@/services/api/company';

// FUNCTION - ENDPOINT IS NOT BEING USED
export function* sendEmailSaga(action: ActionType<typeof sendEmailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(sendEmail, materials);
    const sendEmailResponse: TSendEmailResponse = response as TSendEmailResponse;
    yield put(sendEmailAction.success(sendEmailResponse));
    successCallback?.(sendEmailResponse);
  } catch (err) {
    handleAPIError(err, 'Error.requestVerifyCompanyEmailError');
    yield put(sendEmailAction.failure(err));
    failedCallback?.(err);
  }
}
