import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { checkAccessToken } from '@/utils/handle-authentication';
import { handleAPIError } from '@/utils/handle-api-error';
import { renameProjectAction } from '@/redux/actions/project';
import { renameProject, TRenameProjectResponse } from '@/services/api/project';

// FUNCTION
export function* renameProjectSaga(action: ActionType<typeof renameProjectAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    yield call(checkAccessToken);
    const response = yield call(renameProject, materials);
    const renameProjectResponse: TRenameProjectResponse = response as TRenameProjectResponse;
    yield put(renameProjectAction.success(renameProjectResponse));
    successCallback?.(renameProjectResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(renameProjectAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateProjectBasicError');
  }
}
